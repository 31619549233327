import React from 'react'
import './index.less'
import homeShadow from '../../assets/images/home_shadow.png'
import homeCenter from '../../assets/images/home_center.png'
import cn from '../../assets/images/cn.png'
import us from '../../assets/images/us.png'
import jp from '../../assets/images/jp.png'
import kr from '../../assets/images/kr.png'
import streaming from '../../assets/images/streaming.png'
import video from '../../assets/images/video.png'
import market from '../../assets/images/market.png'
import service from '../../assets/images/service.png'
import train from '../../assets/images/train.png'
import homeHowUse from '../../assets/images/home_howuse.png'
import homeBottom1 from '../../assets/images/home_bottom1.png'
import homeBottom2 from '../../assets/images/home_bottom2.png'
import homeBottom3 from '../../assets/images/home_bottom3.png'

function Home() {
  const homeBottomList = [
    { img: homeBottom1 },
    { img: homeBottom2 },
    { img: homeBottom3 },
  ]

  const TitleSection = () => (
    <div className="home-title-desc">
      <div className="home-title">用AI人工智能创作视频</div>
      <div className="home-desc">
        使用AI合成的声音或配音，轻松制作高品质的视频
      </div>
      <div
        className="home-start"
        onClick={() => window.open('https://web.qimeiai.com', '_blank')}
        style={{ cursor: 'pointer' }}>
        免费开始制作
      </div>
    </div>
  )

  const LanguageSection = () => {
    const languages = [
      { name: '中文', flag: cn },
      { name: '英文', flag: us },
      { name: '韩文', flag: kr },
      { name: '日文', flag: jp },
    ]

    return (
      <div className="home-language">
        <div className="home-language-title">
          支持超过<span className="home-language-title-number">17</span>种语言
        </div>
        <div className="language-list">
          {languages.map((lang, index) => (
            <div className="language-item" key={index}>
              <img src={lang.flag} alt={lang.name} className="language-flag" />
              <div className="language-name">{lang.name}</div>
            </div>
          ))}
          <div className="language-more">...</div>
        </div>
        <div className="home-ai-desc">
          人工智能驱动的视频创作可满足任何需求。
        </div>
        <img className="home-center-img" src={homeCenter} alt="home-center" />
      </div>
    )
  }

  const FunctionSection = () => {
    const featureList = [
      '将文本转换为120多种自然语言',
      '3分钟定制完成专属克隆人',
      '无需设备或视频编辑技能',
    ]

    const serviceList = [
      { icon: streaming },
      { icon: video },
      { icon: market },
      { icon: service },
      { icon: train },
    ]

    return (
      <div className="home-function">
        <div className="home-function-title">在3分钟内创建专业视频</div>
        <div className="home-features">
          {featureList.map((text, index) => (
            <div className="feature-item" key={index}>
              • {text}
            </div>
          ))}
        </div>
        <div className="home-service">
          <div className="service-list">
            {serviceList.map((item, index) => (
              <img className="service-icon" src={item.icon} />
            ))}
          </div>
          <div className="service-more">...</div>
        </div>
      </div>
    )
  }

  const HowUseSection = () => {
    return (
      <div className="home-how-use">
        <div className="home-how-use-title">如何使用</div>
        <div className="home-how-use-desc">
          无论你是专业人士还是初学者，你都可以在几分钟内制作出令人惊叹的视频！
        </div>
        <img className="home-how-use-img" src={homeHowUse}></img>
        <div
          className="home-how-use-start"
          onClick={() => window.open('https://web.qimeiai.com', '_blank')}
          style={{ cursor: 'pointer' }}>
          立即免费制作
        </div>
      </div>
    )
  }

  const BottomSection = () => {
    return (
      <div className="home-bottom">
        <div className="home-bottom-list">
          {homeBottomList.map((item, index) => (
            <img className="home-bottom-img" src={item.img} key={index} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="home">
      <img className="home-shadow" src={homeShadow}></img>
      <TitleSection />
      <LanguageSection />
      <FunctionSection />
      <HowUseSection />
      <BottomSection />
    </div>
  )
}

export default Home
