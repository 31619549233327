import React from 'react'
import './index.less'
import copyrightIcon from '../../assets/images/copyRight.png'

function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <img src={copyrightIcon} className="copyright-icon"></img>
        <div className="copyright-text">
          2024 杭州琪美网络科技有限公司. All Right Reserved
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer">
            浙ICP备2024125746号-1
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
